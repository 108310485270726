import {
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	Link,
	Outlet,
	useLocation,
} from 'react-router-dom';

import { useTranslate } from './components/translate/Translate';
import { Routing } from './branding/Routing';
import { availableLocales } from './branding/locales/Locales';
import logo from './branding/assets/logo.png';
import './App.scss';

const Structure = () => {
	const usetranslate = useTranslate();

	const { pathname } = useLocation();

	const setLanguage = (value) => {
		usetranslate.setLanguage(value);
	};
	return (
		<>
			<header>
				<nav className="navbar" role="navigation">
					<div className="navbar-brand">
						<Link to="/">
							<img id="logo" src={logo} alt="logo" />
						</Link>
					</div>

					<ul className="nav navbar-top-links">
						{availableLocales.map((item) => (
							<li
								className={`languages ${
									usetranslate.language === item.key &&
									'selected'
								}`}
								key={item.key}
							>
								<button
									type="button"
									onClick={() => setLanguage(item.key)}
								>
									{item.label}
								</button>
							</li>
						))}
					</ul>
				</nav>
			</header>
			<main
				className={
					pathname === '/' ? 'home' : pathname.replace('/', '')
				}
			>
				<Outlet />
			</main>
		</>
	);
};

export const App = () => {
	const routing = createBrowserRouter(
		createRoutesFromElements(
			<Route element={<Structure />}>
				<Route
					path="/"
					lazy={() => import(`./features/home/Routing`)}
					exact
				/>
				{Routing}
			</Route>
		),
		{ basename: '/' }
	);

	return <RouterProvider router={routing} />;
};

export default App;
