import './Branding.scss';

export const baseURL = 'https://dev-api.drivingstar.io/';

export const appleStore = 'https://itunes.apple.com';
export const androidStore = 'https://play.google.com';

export const termsAndConditionsLink = 'http://termsandconditions.com';

export const termsOfUseEN = 'https://static.oseven.io/9515b354-7f09-31bc-a294-14d27ee6e2f8/branding/terms.html#referral';
export const termsOfUseGR = 'https://static.oseven.io/9515b354-7f09-31bc-a294-14d27ee6e2f8/branding/terms_el.html#referral';

export const GoogleRecaptchaKey = '6Lcnt5QpAAAAAKcjedpPyCuoA8yiRg_xMkW6SPqA';

const Colors = {
	highlight: '#00DBCD',
	main: '#FFFFFF',
	secondary1: '#818990',
	secondary2: '#0B1115',
	background: '#1F2428',
	widgets: '#2B3238',
	cover1: '#1F2428',
	cover2: '#FFFFFF',
	success: '#0FCA43',
	error: '#FD4964',
	info: '#5AB6EE',
};

export const theme = {
	palette: {
		primary: {
			main: Colors.highlight,
			contrastText: Colors.widgets,
		},
		main: {
			main: Colors.main,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						color: Colors.widgets,
						backgroundColor: Colors.secondary2,
						cursor: 'not-allowed !important',
						pointerEvents: 'auto !important',
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					color: `${Colors.main} !important`,
					'.MuiOutlinedInput-notchedOutline': {
						borderColor: `${Colors.secondary1} !important`,
					},
					'.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: `${Colors.main} !important`,
					},
					'.MuiOutlinedInput-root': {
						color: `${Colors.main} !important`,
					},
					'.Mui-focused': {
						color: `${Colors.main} !important`,
					},
					'.MuiInputBase-input[readonly]': {
						WebkitTextFillColor: `${Colors.secondary1} !important`,
						cursor: 'not-allowed !important',
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					color: `${Colors.main} !important`,
					'.MuiOutlinedInput-notchedOutline': {
						borderColor: `${Colors.secondary1} !important`,
					},
					'.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: `${Colors.main} !important`,
					},
					'.Mui-focused': {
						color: `${Colors.main} !important`,
					},
					'.MuiSelect-icon': {
						color: `${Colors.highlight} !important`,
					},
				},
				icon: {
					color: Colors.secondary1,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: Colors.widgets,
					border: `solid 1px ${Colors.main}`,
					boxShadow: `0px 4px 4px 2px rgba(0,0,0,0.6)`,
					'.MuiMenuItem-root': {
						color: Colors.main,
					},
					'.MuiMenuItem-root:hover': {
						backgroundColor: Colors.secondary2,
						color: Colors.highlight,
					},
					'.MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover': {
						backgroundColor: Colors.secondary2,
						color: Colors.highlight,
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: Colors.secondary1,
					'&.Mui-focused': {
						color: `${Colors.main} !important`,
					},
				},
				shrink: {
					color: `${Colors.main} !important`,
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					'&.Mui-disabled': {
						color: Colors.secondary1,
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: Colors.secondary1,
					paddingTop: '0px',
					paddingBottom: '0px',
					alignSelf: 'flex-start',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				filledSuccess: {
					color: Colors.widgets,
					backgroundColor: Colors.success,
					borderColor: Colors.success,
					boxShadow: 'none',
				},
				filledError: {
					color: Colors.widgets,
					backgroundColor: Colors.error,
					borderColor: Colors.error,
					boxShadow: 'none',
				},
				filledInfo: {
					color: Colors.widgets,
					backgroundColor: Colors.info,
					borderColor: Colors.info,
					boxShadow: 'none',
				},
			},
		},
	},
};
